'use client';

import createFilterRegistry from '@/components/EntryIndex/FilterRegistry/createFilterRegistryContext';

import React from 'react';
import { EntryIndexVariables } from '../entryIndexTypes';

const registry = createFilterRegistry<EntryIndexVariables>({} as EntryIndexVariables);

export const { useFilter: useEntryIndexFilter, useFilterRegistry: useEntryIndexFilterRegistry } =
  registry;

type EntryIndexFilterRegistryProviderProps = React.PropsWithChildren<unknown>;

export const EntryIndexFilterRegistryProvider = ({
  children,
}: EntryIndexFilterRegistryProviderProps) => {
  return <registry.Provider>{children}</registry.Provider>;
};
