/* eslint-disable @typescript-eslint/no-explicit-any */

import { RawCategories } from '@/lib/parsers/categories';
import {
  parseDisplayRoleMode,
  parseDocument,
  parseDocuments,
  parseLinkField,
  parseLinkFields,
  parsePhoneNumber,
  parseVcard,
} from '@/lib/parsers/common';
import { parseImage } from '@/lib/parsers/images';
import { BlocksFragment, EntriesFragment, GlobalsFragment } from '__generated__/graphql';

import { cleanHtml } from '@/lib/utils/htmlHelpers';
import {
  createPropSanitiser,
  SanitiserTypes,
  toBoolean,
  toId,
  toString,
  toStringOrNull,
  trimSlashes,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';
import { parseFormieForm } from '../parsers/form';

/**
 * Union of all Typed Elements that may be passed to the sanitiser
 */
type RawElements = RawCategories | BlocksFragment | EntriesFragment | GlobalsFragment;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseAnything = createPropSanitiser((): RawElements | null => null, {
  content: cleanHtml,
  heading: toStringOrNull,
  anchor: toStringOrNull,
  id: toId,
  imageSingle: parseImage,
  label: toStringOrNull,
  label2: toStringOrNull,
  displayRole: toStringOrNull,
  displayRoleMode: parseDisplayRoleMode,
  linkCta: parseLinkField,
  linkField: parseLinkField,
  subscribeLink: parseLinkField,
  linkFieldMultiple: parseLinkFields,
  pageTitle: toStringOrNull,
  title: toStringOrNull,
  uri: (value?: string | null) => (value ? `/${trimSlashes(value)}/` : ''),
  slug: toString,
  entryImage: parseImage,
  email: toStringOrNull,
  contactCard: parseImage,
  phoneMobile: parsePhoneNumber,
  phoneOffice: parsePhoneNumber,
  documentSingle: parseDocument,
  documentMultiple: parseDocuments,
  vcardSingle: parseVcard,
  flipAlignment: toBoolean,
  mediaRelease: toBoolean,
  displayEntry: toBoolean,
  form: parseFormieForm,

  // seoJson: 'sanitiseSingle',
  // metaLinkContainer: parseSeoMetaLinks,
  // metaTagContainer: parseSeoMetaTags,
  // metaTitleContainer: parseSeoTitle,
  // metaScriptContainer: parseSeoScripts,
  // metaJsonLdContainer: parseSeoJsonLd,
  // metaSiteVarsContainer: parseSeoSiteVars,

  migratedContent: toString,
  migratedContentEnabled: toBoolean,

  /**
   * Recursively sanitise child elements
   */
  blocks: 'sanitise',
  entrySingle: 'sanitiseSingle',
  articleSingle: 'sanitiseSingle',
  articleMultiple: 'sanitise',
  profileSingle: 'sanitiseSingle',
  profileMultiple: 'sanitise',
  locationMultiple: 'sanitise',
  sectorSingle: 'sanitiseSingle',
  sectorMultiple: 'sanitise',
  serviceSingle: 'sanitiseSingle',
  serviceMultiple: 'sanitise',
  productSingle: 'sanitiseSingle',
  productMultiple: 'sanitise',
  socialLinks: 'sanitiseSingle',

  children: 'sanitise',
  // globalSets: 'sanitise',

  // Categories
  profileRoleSingle: 'sanitiseSingle',
  contentTypeCategorySingle: 'sanitiseSingle',
  languageMultiple: 'sanitise',

  siteHandle: toString,
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseAnything, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};
