import React from 'react';
import Btn, { BtnComponentWithRef } from '../ui/Btn';
import * as styles from './CtaButton.css';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';

// export type CtaButtonProps = Link;

const CtaButton: BtnComponentWithRef = React.forwardRef(function CtaButton(props, ref) {
  return (
    <Btn
      ref={ref}
      variant="text"
      endIcon="arrowRight"
      EndIconProps={{ cx: { color: 'accent' } }}
      {...props}
      {...mergePropsClassName(props, styles.root)}
    />
  );
});

export default CtaButton;
