'use client';
/**
 * Converts the given URLSearchParams object into a sorted string representation.
 *
 * @param searchParams - The URLSearchParams object to be converted.
 * @returns A string representation of the sorted URLSearchParams object.
 */

export const stringifySearchParams = (searchParams: URLSearchParams | null) => {
  const query = new URLSearchParams(searchParams ?? '');
  query.sort();

  return query.toString();
};
