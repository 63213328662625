const parseAllowedKinds = (maybeAllowedKinds: MaybeArrayOf<string>) => {
  const defaultAllowedKinds = ['image', 'pdf'];

  const allowedKinds = Array.isArray(maybeAllowedKinds) ? maybeAllowedKinds : defaultAllowedKinds;

  return allowedKinds
    ?.map((kind) => {
      switch (kind) {
        case 'image':
          return 'image/*';
        case 'pdf':
          return 'application/pdf';
        case 'audio':
          return 'audio/*';
        case 'video':
          return 'video/*';
        case 'spreadsheet':
          return 'application/vnd.ms-excel';
        case 'word':
        case 'document':
          return [
            '.doc',
            '.docx',
            'application/msword',
            'application / vnd.openxmlformats - officedocument.wordprocessingml.document',
          ].join(',');
        case 'presentation':
          return 'application/vnd.ms-powerpoint';
        case 'archive':
          return 'application/zip';
        case 'code':
          return 'text/*';
        default:
          return kind;
      }
    })
    .filter((v) => !!v)
    .join(',');
};

export default parseAllowedKinds;
