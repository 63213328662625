'use client';

import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import devLog from '@/lib/utils/devLog';
import deepEqual from 'deep-equal';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { createStore, StateCreator, useStore } from 'zustand';
import { EntryIndexFilterState } from '../entryIndexTypes';
import { getStateFromParams } from './getStateFromParams';
import normaliseFilterSelections from './normaliseFilterSelections';
import { searchParamsFromState } from './selectQueryString';
import { stringifySearchParams } from './stringifySearchParams';

const normaliseState = (state: EntryIndexFilterState) => {
  state.filterSelections = normaliseFilterSelections(state.filterSelections);
  state.page = state.page || 1;
  return state;
};

const getStateChanged = <T extends EntryIndexFilterState | null>(
  initialState: T,
  currentState: T
) => {
  const a = initialState ? normaliseState(initialState) : null;
  const b = currentState ? normaliseState(currentState) : null;

  return !deepEqual(a, b);
};

enum MODE {
  'INIT',
  'IDLE',
  'UPDATING_STATE',
  'UPDATING_PARAMS',
}

interface SearchParamsState {
  initialised: boolean;
  mode: MODE;
  queryString?: string | null;
  indexState: EntryIndexFilterState | null;
  searchParams: URLSearchParams | null;
  filterIds: string[];
}

type SearchParamsActionPayload = {
  searchParams?: URLSearchParams;
  indexState?: EntryIndexFilterState;
  updateParamsCallback?: (queryString?: string | null) => void;
  updateStateCallback?: (state: EntryIndexFilterState) => void;
};

type MaybeSearchParams = URLSearchParams | string | null | undefined;

const normaliseSearchParams = (searchParams: MaybeSearchParams) => {
  const params = new URLSearchParams(searchParams?.toString());
  params.sort();
  return params;
};

const getSearchParamsChanged = (
  searchParams: MaybeSearchParams,
  newSearchParams: MaybeSearchParams
) => {
  const prevParams = normaliseSearchParams(searchParams).toString();
  const newParams = normaliseSearchParams(newSearchParams).toString();
  return prevParams !== newParams;
};

const searchParamsReducer = (
  state: SearchParamsState,
  payload: SearchParamsActionPayload
): SearchParamsState => {
  const { initialised } = state;
  if (!initialised) return state;

  devLog(''); // spacer

  // const currentMode = state.mode;

  const newState: SearchParamsState = { ...state };

  let indexStateChanged = false;
  let paramsChanged = false;

  // Use the provided search params, which may be overridden by the index state
  let newParams = payload.searchParams ?? null;
  let newQueryString = state.queryString ?? null;

  let newMode: MODE = MODE.IDLE;

  if (!payload.indexState) newMode = MODE.INIT;
  if (!payload.searchParams) newMode = MODE.INIT;

  const isInit = newMode === MODE.INIT;

  if (payload.indexState) {
    const newIndexState = normaliseState(payload.indexState);
    indexStateChanged = getStateChanged(state.indexState, newIndexState);
    newState.indexState = indexStateChanged ? newIndexState : state.indexState;
  }

  if (indexStateChanged) {
    // devLog('INDEX_STATE_CHANGED');
    // If the state has changed, we need to update the search params
    newParams = searchParamsFromState(newState.indexState);
    if (!isInit) newMode = MODE.UPDATING_PARAMS;
  }

  if (newParams) {
    paramsChanged = getSearchParamsChanged(state.searchParams, newParams);
    newState.searchParams = paramsChanged ? newParams : state.searchParams;
  }

  if (paramsChanged) {
    if (!isInit && newMode !== MODE.UPDATING_PARAMS) newMode = MODE.UPDATING_STATE;

    // devLog('PARAMS_CHANGED');
    newQueryString = stringifySearchParams(newState.searchParams) || null;
    newState.queryString = stringifySearchParams(newState.searchParams) || null;
  }

  const queryStringChanged = (newQueryString ?? '') !== (state.queryString ?? '');

  switch (newMode) {
    case MODE.UPDATING_PARAMS:
      devLog('UPDATING_PARAMS');
      if (queryStringChanged) {
        devLog('QUERY_STRING_CHANGED');
        payload.updateParamsCallback?.(newQueryString);
      }
      break;
    case MODE.UPDATING_STATE:
      devLog('UPDATING_STATE');
      const stateFromNewParams = getStateFromParams(newState.searchParams, state.filterIds);
      newState.indexState = stateFromNewParams;
      payload.updateStateCallback?.(stateFromNewParams);
      break;
  }

  newState.mode = newMode;

  // const indexState = state.indexState;

  return newState;
};

interface SearchParamsStateWithActions extends SearchParamsState {
  dispatch: (action: SearchParamsActionPayload) => void;
  init: (state: Omit<Partial<SearchParamsState>, 'initialised'>) => void;
  destroy: (state?: Partial<SearchParamsState>) => void;
}

const defaultSearchParamsState: SearchParamsState = {
  initialised: false,
  mode: MODE.INIT,
  indexState: null,
  searchParams: null,
  filterIds: [],
};

// const useSearchParamsStore = create<SearchParamsStore>((set, get) => ({
//   ...defaultSearchParamsState,
//   dispatch: (action) => set((state) => searchParamsReducer(state, action)),
//   init: (state) => !get().initialised && set({ ...state, initialised: true }),
//   destroy: (state) => {
//     devLog('destroySearchParams');
//     set({ ...defaultSearchParamsState, ...state });
//   },
// }));

const searchParamsStoreCreator: StateCreator<SearchParamsStateWithActions> = (set, get) => ({
  ...defaultSearchParamsState,
  dispatch: (action) => set((state) => searchParamsReducer(state, action)),
  init: (state) => !get().initialised && set({ ...state, initialised: true }),
  destroy: (state) => {
    devLog('destroySearchParams');
    set({ ...defaultSearchParamsState, ...state });
  },
});

export const createSearchParamsStore = () => createStore(searchParamsStoreCreator);
export type SearchParamsStore = ReturnType<typeof createSearchParamsStore>;

export const SearchParamsStoreContext = React.createContext<SearchParamsStore | null>(null);

export function useSearchParamsStore<T>(selector: (state: SearchParamsStateWithActions) => T): T {
  const store = React.useContext(SearchParamsStoreContext);
  if (!store) throw new Error('Missing SearchParamsStoreContext.Provider in the tree');
  return useStore(store, selector);
}

export default function useEntryIndexSearchParams() {
  const router = useRouter();
  const pathname = usePathname();
  const writeParamsEnabled = useEntryIndex((s) => !!s.writeUrlParams);
  const searchParams = useSearchParams();
  const filterIds = useEntryIndex((s) => Object.keys(s.filters));
  const setState = useEntryIndex((s) => s.setState);
  const [initialReady, setInitialReady] = React.useState(false);
  const currentState = useEntryIndex((s) => ({
    filterSelections: s.filterSelections,
    page: s.page,
    search: s.search,
  }));
  // const [stateMemo, setStateMemo] = React.useState<EntryIndexFilterState>({});

  // useEntryIndexSearchParamsSetters(initialReady && writeParamsEnabled);

  // ------------------------------
  // const destroySearchParams = useSearchParamsStore((s) => s.destroy);
  const initSearchParams = useSearchParamsStore((s) => s.init);
  const searchParamsDispatch = useSearchParamsStore((s) => s.dispatch);

  React.useEffect(() => {
    /**
     * If we're not using the writeUrlParams feature, we don't need to do anything
     */
    if (!writeParamsEnabled) return;

    searchParamsDispatch({
      searchParams,
      indexState: currentState,
      updateParamsCallback: (queryString) => {
        const newPath = queryString ? `${pathname}?${queryString}` : pathname;
        router.push(newPath, { scroll: false });
      },
      updateStateCallback: setState,
    });
  }, [currentState, pathname, router, searchParams, searchParamsDispatch]);

  // React.useEffect(() => {
  //     console.log('destroySearchParams');
  //     destroySearchParams();
  // }, [pathname]);

  // ------------------------------

  React.useEffect(() => {
    /**
     * If we're not using the writeUrlParams feature, we don't need to do anything
     */
    if (!writeParamsEnabled) return;

    const stateFromParams = getStateFromParams(searchParams, filterIds);
    if (initialReady) return;

    // destroySearchParams();

    initSearchParams({
      searchParams,
      indexState: currentState,
      filterIds,
    });

    // setStateMemo(stateFromParams);
    setState(stateFromParams);

    setInitialReady(true);
  }, [
    currentState,
    filterIds,
    initialReady,
    searchParams,
    setState,
    // stateMemo,
    writeParamsEnabled,
  ]);

  /*
  const [loadedParams, setLoadedParams] = React.useState<string>();
  React.useEffect(() => {
    const paramsString = stringifySearchParams(searchParams);

    if (paramsString && loadedParams === paramsString) return;
    devLog('searchParams changed', paramsString);

    setLoadedParams(paramsString);

    return () => {
      // console.log('searchParams cleanup');
    };
  }, [loadedParams, searchParams]); */

  return {
    ready: initialReady || !writeParamsEnabled,
  };
}
