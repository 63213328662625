'use client';
import { PAGE_PARAM, SEARCH_PARAM } from '../entryIndexConstants';
import { EntryIndexFilterState, FilterSelections } from '../entryIndexTypes';

export const getStateFromParams = (
  maybeSearchParams: URLSearchParams | null,
  filterIds: string[]
): EntryIndexFilterState => {
  const searchParams = maybeSearchParams ?? new URLSearchParams();

  const search = searchParams.get(SEARCH_PARAM);

  const page = parseInt(searchParams.get(PAGE_PARAM) ?? '1', 10);

  const filterSelections = filterIds.reduce<FilterSelections>((acc, filterId) => {
    const values = searchParams.getAll(filterId);
    if (values.length) {
      acc[filterId] = values;
    }
    return acc;
  }, {});

  return { filterSelections, page, search };
};
