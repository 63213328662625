/* eslint-disable @typescript-eslint/no-explicit-any */
import { stripHtmlTags } from '@/lib/utils/htmlHelpers';
import { isString, toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';

export type Alignment = 'left' | 'auto' | 'right';
export type DisplayRoleMode = 'none' | 'appendToRole' | 'overwriteRole';
export type TextAlignment = 'auto' | 'center' | 'left' | 'right';
export type PhoneNumber = string;

/**
 * Check and set the 'alignment' field
 */
export const parseTextAlignment = (maybeAlignment: any): TextAlignment => {
  switch (maybeAlignment) {
    case 'center':
      return 'center';
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};
/**
 * Check and set the 'alignment' field
 */
export const parseAlignment = (maybeAlignment: any): Alignment => {
  switch (maybeAlignment) {
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};

export const parseDisplayRoleMode = (maybeDisplayRoleMode: any): DisplayRoleMode => {
  switch (maybeDisplayRoleMode) {
    case 'appendToRole':
      return 'appendToRole';
    case 'overwriteRole':
      return 'overwriteRole';
    case 'none':
      return 'none';
    default:
      return 'none';
  }
};

export const parsePhoneNumber = (maybePhoneNumber: Maybe<any>): PhoneNumber | null => {
  if (!maybePhoneNumber) return null;

  if (isString(maybePhoneNumber)) return maybePhoneNumber;

  if (
    (maybePhoneNumber.number || maybePhoneNumber.formatForCountry) &&
    typeof (maybePhoneNumber.number || maybePhoneNumber.formatForCountry) === 'string'
  ) {
    const number = maybePhoneNumber.formatForCountry ?? maybePhoneNumber.number;

    return toStringOrNull(number);
  }
  return null;
};

/**
 * Replaces line breaks in a string with an HTML <br> tag.
 *
 * @param {string | undefined} text - The text to process.
 * @returns {string | undefined} The processed text.
 */
export const replaceLineBreaksWithBr = (text?: Maybe<string>) => {
  return text?.replace(/\n/g, '<br>');
};

export const sanitiseHeading = (maybeHeading?: Maybe<string>) => {
  return stripHtmlTags(replaceLineBreaksWithBr(maybeHeading), ['strong', 'br']);
};
