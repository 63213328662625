'use client';

import { EntryIndexQuery, EntryResultItem } from '@/components/EntryIndex/entryIndexTypes';
import useEntryIndexSearchParams from '@/components/EntryIndex/searchParams/useEntryIndexSearchParams';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { SEARCH_ENTRIES_QUERY } from '@/gql/queries/entryIndexQueries.gql';
import { parseEntries } from '@/lib/parsers/entries';
import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import { EntriesFragment } from '__generated__/graphql';
import React, { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useEntryIndexFilterRegistry } from './Filters/useEntryIndexFilterRegistry';
import { selectActions, selectIndexQueryVariables } from './entryIndexSelectors';

type EntryIndexQueryHandlerProps<T extends EntryResultItem, Q extends EntryIndexQuery<T>> = {
  /**
   * A custom query to use for the entry index
   * @todo Fix the typing here
   */
  customQuery?: TypedDocumentNode<Q>;
};

export const useEntryIndexQueryHandler = <T extends EntryResultItem, Q extends EntryIndexQuery<T>>(
  props?: EntryIndexQueryHandlerProps<T, Q>
) => {
  const query = props?.customQuery || SEARCH_ENTRIES_QUERY;
  const { applyFilters: _applyFilters } = useEntryIndexFilterRegistry(
    ({ applyFilters, filters }) => ({
      applyFilters,
      filters,
    })
  );
  const apolloClient = useApolloClient();
  const initialQuery = useEntryIndex(useShallow(selectIndexQueryVariables));
  const variables = initialQuery; //React.useMemo(() => applyFilters(initialQuery), [applyFilters, initialQuery]);
  const { ready } = useEntryIndexSearchParams();

  // Convert variables to string to compare
  const variablesStr = JSON.stringify(variables);

  const actions = useEntryIndex(selectActions);

  const [prevVariablesStr, setPrevVariablesStr] = React.useState('');
  const hasChanges = variablesStr !== prevVariablesStr;

  const triggerQuery = useCallback(
    (force?: boolean) => {
      if (!hasChanges && !force) return;

      actions.setLoading(true);

      const watchedQuery = apolloClient.watchQuery({
        query,
        variables,
        // fetchPolicy: params?.fetchPolicy || "cache-and-network",
      });

      const sub = watchedQuery.subscribe({
        next(x) {
          if (!x.partial) {
            setPrevVariablesStr(variablesStr);
            const entries = parseEntries(x.data.entries as MaybeArrayOf<EntriesFragment>);
            actions.setItems(entries);
            actions.setTotalItems(x.data.entryCount ?? 0);
            actions.setError(null);
            actions.setLoading(false);
            actions.setInitialLoaded(true);
          }
        },
        error(err) {
          actions.setError(err);
          actions.setLoading(false);
        },
        complete() {
          actions.setLoading(false);
        },
      });

      return () => {
        sub.unsubscribe();
      };
    },
    [actions, apolloClient, hasChanges, query, variables, variablesStr]
  );

  return {
    triggerQuery,
    hasChanges,
    ready,
  };
};
