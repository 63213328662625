'use client';

import { EntryIndexFilterRegistryProvider } from '@/components/EntryIndex/Filters/useEntryIndexFilterRegistry';
import {
  createEntryIndexStore,
  EntryIndexContext,
  EntryIndexStore,
} from '@/components/EntryIndex/useEntryIndex';
import { PropsWithChildren, useRef } from 'react';
import EntryIndexAutoSubmit from './EntryIndex.AutoSubmit';
import { IndexProps } from './entryIndexTypes';
import {
  createSearchParamsStore,
  SearchParamsStore,
  SearchParamsStoreContext,
} from './searchParams/useEntryIndexSearchParams';

export type EntryIndexProps = PropsWithChildren<IndexProps>;

const EntryIndexProvider = ({ children, ...props }: EntryIndexProps) => {
  const storeRef = useRef<EntryIndexStore>();
  const searchParamsStoreRef = useRef<SearchParamsStore>();

  if (!storeRef.current) {
    storeRef.current = createEntryIndexStore(props);
  }

  if (!searchParamsStoreRef.current) {
    searchParamsStoreRef.current = createSearchParamsStore();
  }

  return (
    <EntryIndexContext.Provider value={storeRef.current}>
      <SearchParamsStoreContext.Provider value={searchParamsStoreRef.current}>
        <EntryIndexFilterRegistryProvider>
          <EntryIndexAutoSubmit />
          {children}
        </EntryIndexFilterRegistryProvider>
      </SearchParamsStoreContext.Provider>
    </EntryIndexContext.Provider>
  );
};

export default EntryIndexProvider;
