/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormErrors } from '@/components/Formie/formTypes';
import { isApolloError } from '@apollo/client';

export const parseFormSubmissionErrors = (maybeError: any): FormErrors | null => {
  let formErrors: FormErrors | null = null;
  try {
    if (isApolloError(maybeError)) {
      const { graphQLErrors = [] } = maybeError;

      formErrors = graphQLErrors.reduce((results, item) => {
        const { message } = item;

        try {
          const parsedMessage = JSON.parse(message) as FormErrors;

          return {
            ...results,
            ...parsedMessage,
          };
        } catch (e) {
          return {
            ...results,
            error: [message],
          };
        }
      }, {} as FormErrors);
    } else if (maybeError instanceof Error) {
      console.log('Caught Error', maybeError?.message);
      formErrors = { error: ['An error occurred.'] };
    }
  } catch (error) {
    console.error(error);
  }

  return formErrors;
};
