import React from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

interface QueryVariables {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type FilterValue = string[] | undefined;

export type FilterCallback<Q extends QueryVariables, T extends FilterValue = FilterValue> = (
  variables: Q,
  value?: T
) => Q;

export interface FilterOption {
  value: string;
  label: string;
  selected?: boolean;
}

export type FilterOptions = FilterOption[];

export interface Filter<Q extends QueryVariables, T extends FilterValue> {
  id: string;
  options?: FilterOptions;
  defaultValue?: T;
  priority?: number;
  getOptions?: () => Promise<FilterOptions> | FilterOptions;
  getOptionsDeps?: React.DependencyList;
  callback?: FilterCallback<Q, T>;
  callbackDeps?: React.DependencyList;
}

export interface FilterRegistryValues<T extends QueryVariables> {
  initialQuery: T;
  filters: Filter<T, FilterValue>[];
  values: Record<string, FilterValue>;
  options: Record<string, FilterOptions>;
}

interface FilterRegistryActions<T extends QueryVariables> {
  setValues: (values: Record<string, FilterValue>) => void;
  setFilterValue: <V extends FilterValue>(id: string, value: V) => void;
  setFilterOptions: (id: string, options: FilterOptions) => void;
  applyFilters: (initialQuery?: T) => T;
  register: (filter: Filter<T, FilterValue>, priority?: number) => () => void;
  deregister: (filterId: string) => void;
}

interface FilterRegistry<T extends QueryVariables>
  extends FilterRegistryValues<T>,
    FilterRegistryActions<T> {}

type FilterRegistryState<T extends QueryVariables> = FilterRegistry<T>;

type FilterRegistryStore<T extends QueryVariables> = StoreApi<FilterRegistry<T>>;

const createFilterRegistryStore = <T extends QueryVariables>(
  initialQuery: T
): FilterRegistryStore<T> => {
  return createStore<FilterRegistry<T>>((set, get) => ({
    initialQuery,
    filters: [],
    values: {},
    options: {},
    setValues: (values) => {
      set({ values });
    },
    setFilterValue: (id, value) => {
      set({ values: { ...get().values, [id]: value } });
    },
    setFilterOptions: (id, options) => {
      set({ options: { ...get().options, [id]: options } });
    },
    applyFilters: (initialQuery) => {
      initialQuery = initialQuery || get().initialQuery;

      return get().filters.reduce((acc, { id, callback }) => {
        if (!callback) return acc;
        const value = get().values[id];
        return callback(acc, value);
      }, initialQuery);
    },
    register: (filter) => {
      const currentFilters = get().filters;
      const filterId = filter.id;
      const filters = [...currentFilters, filter];
      const defaultValue = get().values[filterId] ?? filter.defaultValue;

      const filterExists = currentFilters.some((f) => f.id === filterId);
      if (filterExists) console.warn(`Filter with id "${filterId}" already exists`);

      // Sort filters by priority
      filters.sort((a, b) => (a.priority || 0) - (b.priority || 0));

      set({
        filters,
        values: {
          ...get().values,
          [filterId]: defaultValue,
        },
      });

      return () => get().deregister(filterId);
    },
    deregister: (filterId) => {
      const filters = get().filters.filter((v) => v.id !== filterId);

      const values = { ...get().values };
      delete values[filterId];

      set({
        filters,
        values,
      });
    },
  }));
};

const createFilterRegistry = <T extends QueryVariables>(initialQuery: T) => {
  const Context = React.createContext<FilterRegistryStore<T> | null>(null);

  function useFilterRegistry<U>(selector: (state: FilterRegistryState<T>) => U): U {
    const store = React.useContext(Context);
    if (!store) throw new Error('Missing FilterRegistryProvider in the tree');
    return useStore(store, selector);
  }

  const Provider = (props: React.PropsWithChildren) => {
    const storeRef = React.useRef<FilterRegistryStore<T>>();

    if (!storeRef.current) {
      storeRef.current = createFilterRegistryStore(initialQuery);
    }

    return <Context.Provider value={storeRef.current}>{props.children}</Context.Provider>;
  };

  function useFilter<V extends FilterValue>(props: Filter<T, V>) {
    const registerFilter = useFilterRegistry((s) => s.register);

    const {
      id,
      callback: maybeCallback,
      callbackDeps = [],
      options: maybeOptions,
      getOptions: maybeGetOptions,
      getOptionsDeps = [],
      defaultValue,
    } = props;

    const hasCallback = typeof maybeCallback === 'function';
    const callback = hasCallback
      ? maybeCallback
      : (((variables: T) => variables) as FilterCallback<T, V>);

    /**
     * Memoize the callback to prevent unnecessary re-renders
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedCallback = React.useCallback(callback, callbackDeps);

    const hasOptionsCallback = typeof maybeGetOptions === 'function';
    const getOptions = hasOptionsCallback ? maybeGetOptions : () => maybeOptions ?? [];

    /**
     * Memoize the getOptions function to prevent unnecessary re-renders
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedGetOptions = React.useCallback(getOptions, getOptionsDeps);

    const options = useFilterRegistry((s) => (s.options[id] ?? maybeOptions) as FilterOptions);

    const setOptions = useFilterRegistry(
      (s) => (options: FilterOptions) => s.setFilterOptions(id, options)
    );

    React.useEffect(() => {
      if (!hasOptionsCallback) {
        if (Array.isArray(maybeOptions)) setOptions(maybeOptions);
        return;
      }
      Promise.resolve(memoizedGetOptions()).then((newOptions) => {
        console.log('newOptions', newOptions);
        setOptions(newOptions);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedGetOptions, hasOptionsCallback]);

    const value = useFilterRegistry((s) => s.values[id] as V | undefined);

    const setValue = useFilterRegistry((s) => (newValue: V | undefined) => {
      s.setFilterValue(id, newValue);
    });

    React.useEffect(() => {
      if (!hasCallback) return;
      return registerFilter({
        id,
        defaultValue,
        callback: memoizedCallback as FilterCallback<T>,
      });
    }, [registerFilter, memoizedCallback, id, defaultValue, hasCallback]);

    React.useEffect(() => {
      console.log('memoizedGetOptions changed');
    }, [memoizedGetOptions]);
    React.useEffect(() => {
      console.log('hasOptionsCallback changed');
    }, [hasOptionsCallback]);
    React.useEffect(() => {
      console.log('registerFilter changed');
    }, [registerFilter]);
    React.useEffect(() => {
      console.log('memoizedCallback changed');
    }, [memoizedCallback]);
    React.useEffect(() => {
      console.log('id changed');
    }, [id]);
    React.useEffect(() => {
      console.log('defaultValue changed');
    }, [defaultValue]);
    React.useEffect(() => {
      console.log('hasCallback changed');
    }, [hasCallback]);

    const resetValue = React.useCallback(() => {
      setValue(defaultValue);
    }, [setValue, defaultValue]);

    const clearValue = React.useCallback(() => {
      setValue(undefined);
    }, [setValue]);

    return {
      options: options ?? [],
      setOptions,
      value,
      setValue,
      resetValue,
      clearValue,
    };
  }

  return {
    Context,
    Provider,
    useFilter,
    useFilterRegistry,
  };
};

export default createFilterRegistry;
