'use client';

import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useEntryIndexQueryHandler } from '@/components/EntryIndex/useEntryIndexQueryHandler';
import React from 'react';

/**
 * This component is responsible for triggering the query
 * when the entry index has changes and autoSubmit is enabled
 */
export default function EntryIndexAutoSubmit() {
  const { hasChanges, ready, triggerQuery } = useEntryIndexQueryHandler();
  const autoSubmit = useEntryIndex((s) => s.autoSubmit);
  const initialLoaded = useEntryIndex((s) => s.initialLoaded);
  const skipInitialQuery = useEntryIndex((s) => s.skipInitialQuery);

  const shouldAutoSubmit = ready && (initialLoaded || skipInitialQuery) && autoSubmit && hasChanges;
  const shouldDoInitial = ready && !skipInitialQuery && !initialLoaded;

  React.useEffect(() => {
    if (!shouldAutoSubmit) return;
    // console.log('triggering auto query');
    return triggerQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoSubmit]);

  React.useEffect(() => {
    if (!shouldDoInitial) return;
    // console.log('triggering initial query');
    return triggerQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDoInitial]);

  return null;
}
