import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';
import { FilterSelections } from '../entryIndexTypes';

/**
 * Normalizes the filter selections by sorting array values.
 * Useful for comparing filter selections.
 *
 * @param data - The filter selections to be normalized.
 * @returns The normalized filter selections.
 */
export default function normaliseFilterSelections(data?: FilterSelections): FilterSelections {
  return mapObject({ ...data }, (value) => {
    if (Array.isArray(value)) {
      return [...value].sort();
    }

    return value;
  });
}
