import {
  deleteUndefined,
  firstNonNullable,
  humanFileSize,
  makeNonNullableArray,
  parseUri,
  toString,
  toStringOrNull,
} from '@liquorice/allsorts-craftcms-nextjs';
import { CommonLinksQuery, DocumentFragment, VcardFragment } from '__generated__/graphql';

export type CommonLink = {
  id: ID;
  title: string;
  uri: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseCommonLink = (data: any): CommonLink => {
  return {
    id: toString(data?.id),
    title: toString(data?.title),
    uri: parseUri(data?.uri) ?? null,
  };
};

export type CommonLinks = ReturnType<typeof parseCommonLinks>;
export type CommonLinkId = keyof CommonLinks;

export function parseCommonLinks(data: CommonLinksQuery) {
  return {
    home: parseCommonLink(data.home),
    articleIndex: parseCommonLink(data.articleIndex),
    profileIndex: parseCommonLink(data.profileIndex),
    areaIndex: parseCommonLink(data.areaIndex),
    search: parseCommonLink(data.search),
  };
}

export type DocumentAsset = NonNullable<ReturnType<typeof parseDocument>>;

export const parseDocument = (maybeElement?: MaybeArrayOf<Partial<DocumentFragment>>) => {
  const doc = firstNonNullable(maybeElement);
  if (!doc) return null;
  const { size, ...rest } = doc;

  // const summary = toStringOrNull(maybeGet(doc, 'summary')) ?? undefined;
  const title = toStringOrNull(doc.title) ?? undefined;
  const id = toStringOrNull(doc.id) ?? undefined;

  return deleteUndefined({
    ...rest,
    id,
    title,
    /* summary, */
    size,
    sizeHuman: humanFileSize(size),
  });
};

export const parseDocuments = (maybeElements?: MaybeArrayOf<DocumentFragment>) => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseDocument(item);
    if (asset) results.push(asset);
    return results;
  }, [] as DocumentAsset[]);
};

export const parseVcard = (maybeElement?: MaybeArrayOf<Partial<VcardFragment>>) => {
  const card = firstNonNullable(maybeElement);
  if (!card) return null;
  const { ...rest } = card;

  const title = toStringOrNull(card.title) ?? undefined;
  const id = toStringOrNull(card.id) ?? undefined;

  return deleteUndefined({
    ...rest,
    id,
    title,
  });
};
