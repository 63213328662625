import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tZjVtam9sMDogIzAwNDY3OTsKICAtLWY1bWpvbDE6ICNDREVBRkY7CiAgLS1mNW1qb2wyOiAjRjFGOUZGOwogIC0tZjVtam9sMzogI0ZBMjQ1ODsKICAtLWY1bWpvbDQ6ICM2NjY2NjY7CiAgLS1mNW1qb2w1OiAjRTNFOUVEOwogIC0tZjVtam9sNjogIzAwMDAwMDsKICAtLWY1bWpvbDc6ICNGRkZGRkY7Cn0KLmY1bWpvbGEgewogIC0tZjVtam9sODogdmFyKC0tZjVtam9sMCk7CiAgLS1mNW1qb2w5OiB2YXIoLS1mNW1qb2wxKTsKfQouZjVtam9sYiB7CiAgLS1mNW1qb2w4OiB2YXIoLS1mNW1qb2wxKTsKICAtLWY1bWpvbDk6IHZhcigtLWY1bWpvbDYpOwp9Ci5mNW1qb2xjIHsKICAtLWY1bWpvbDg6IHZhcigtLWY1bWpvbDIpOwogIC0tZjVtam9sOTogdmFyKC0tZjVtam9sNik7Cn0KLmY1bWpvbGQgewogIC0tZjVtam9sODogdmFyKC0tZjVtam9sMyk7CiAgLS1mNW1qb2w5OiB2YXIoLS1mNW1qb2w3KTsKfQouZjVtam9sZSB7CiAgLS1mNW1qb2w4OiB2YXIoLS1mNW1qb2w0KTsKICAtLWY1bWpvbDk6IHZhcigtLWY1bWpvbDcpOwp9Ci5mNW1qb2xmIHsKICAtLWY1bWpvbDg6IHZhcigtLWY1bWpvbDUpOwogIC0tZjVtam9sOTogdmFyKC0tZjVtam9sNik7Cn0KLmY1bWpvbGcgewogIC0tZjVtam9sODogdmFyKC0tZjVtam9sNik7CiAgLS1mNW1qb2w5OiB2YXIoLS1mNW1qb2w3KTsKfQouZjVtam9saCB7CiAgLS1mNW1qb2w4OiB2YXIoLS1mNW1qb2w3KTsKICAtLWY1bWpvbDk6IHZhcigtLWY1bWpvbDYpOwp9Ci5mNW1qb2xpIHsKICAtLWY1bWpvbDg6IHZhcigtLWY1bWpvbDcpOwogIC0tZjVtam9sOTogdmFyKC0tZjVtam9sNik7Cn0KYm9keSB7CiAgY29sb3I6IHZhcigtLWY1bWpvbDYsICMwMDApOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWY1bWpvbDcsICNmZmYpOwp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WUy1KDMBSG932Ks2zHciYnIdDGrUvfwaE0tiiXSrGOOr67E6BASNj%2B%2F3znnqi6qhr4XQEEwQtlMT9kxBQ86fxWlQk8Z6dz82i5pCDNk%2BKyFihrXWwhTfJ0TUi1LuABJDLGbl%2BbLRh3Y7N8ZPkE5shkR3OUPR26tBjoKco7kjCK4pbs6pqx4Z3lGFuZRZ%2BZ4U6IjndpqYAjtSXbRrRkxAoIydZ2RuO2tjeasLXEaKGtHYwmbS1d2MS%2B60ig6DsKfRM53mmydkHDLhiG1E2UUMSeCFoBOY2%2FjoOeRGVuUd41nUbaX9R4IJ41nQd6ijoHwn2Zs3EcsZXZPRDu0m9qGJJtvLc7d%2FXcN7tiYXZ9BSHScOK%2BfZQLuar2Fj3GRZmuPFV%2FGMOj1wpCxmztqiCaa40COdc%2BFQQMGZ%2FHvPW6NMbf6lAdv9v%2F6LUqm%2BCa%2FUyuLM9KHZy1%2BZOG55DrptF1cL0kaVaerFj%2FxnEXsNwEAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMjVyZW07CiAgLS1rZG12cWk3OiAwLjVyZW07CiAgLS1rZG12cWk4OiAwLjc1cmVtOwogIC0ta2RtdnFpOTogMXJlbTsKICAtLWtkbXZxaWE6IDEuMjVyZW07CiAgLS1rZG12cWliOiAxLjVyZW07CiAgLS1rZG12cWljOiAycmVtOwogIC0ta2RtdnFpZDogMi41cmVtOwogIC0ta2RtdnFpZTogY2xhbXAoMi41cmVtLCBjYWxjKDEuN3JlbSArIDEuNjY3dncpLCAzcmVtKTsKICAtLWtkbXZxaWY6IGNsYW1wKDNyZW0sIGNhbGMoMi4ycmVtICsgMS42Njd2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlnOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS45cmVtICsgMy4zMzN2dyksIDQuNXJlbSk7CiAgLS1rZG12cWloOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS4xcmVtICsgNS4wMDB2dyksIDVyZW0pOwogIC0ta2RtdnFpaTogY2xhbXAoNHJlbSwgY2FsYygtMS42cmVtICsgMTEuNjY3dncpLCA3LjVyZW0pOwp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U626DMAyF%2F%2B8p%2FLOVmioOt9Z7mIlbO1ZKWKAd3bR3n4LaLk40dRII8XHOsQk4ZLQe4esJQAhtpv6QSoKtlM8OQQKUHCkCjDiKCNh9TJBkaT%2B5LCHI0g1nqU1XMYcZAarYU24IFCdbgkG3TeWy3OujIJDrxNRHF5YWbjIfVwS4RuXjmkAFETuCc24WQrxgk6miwX7pPt4ToOd4tUWD8MbWjIJW3kL%2Fwa%2FYsYpt6DjaikF05%2Bc0LEfbtw0Xobd9hkv2TiD7aT7TfgKzL%2FKFXMH1WGPEss2vGuVj%2BeDI%2F5E%2B0r0PdfP5FpUsV%2FfQ%2BA8RJiz3NH%2B4wUVnmmWMfYSyyaKIoYtFMUOf8y%2FKEEqCIi8Pe6NPXSVK3Wqzguul0KaqzQ3OfUCdD7VoOqFPI8tBAt3nZTNeHggVwWjybthpc3wg9UcdYwJvgDEh8MYX7aR7GwJmBJG07PsHFVcHoYoEAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82X247aMBRF3%2FmK89hKCYKYy0zmrT9SObETm%2FgSHDOFVv33inAZmyIbRjDKG3KW99nBPt7x2O4sqyYz%2BDMCKLXQJod3bL6l6eHBJAGuGDXcfn8bAVRa2fQX5TWzPpe53N%2FR%2BDA672XPWqcp1VyutJi45CJETl1yGSIzl3wJkcglX0PkzCVxiJy7ZBEiFy5ZhsilS5KelNjUXKWFtlbLHCbjBZUORHvoykr9nPJlVvBp50pWMdpZ%2BApLLnaXwKQHBFc0ZVcVvFeoH1SvBzr%2Bm14%2BrmJ2vN3Enm2njtnxtix%2Fth0Ws%2BP1xerZdnjMjtd8zbPtrGJ2vA4Xz7bTxOx4x4j8sHNNbH1Xm6qwWBsTe3HFdFhMx8ReXbE2LKZiYtgVW3%2BIfWqFZKxa6VYzsQ1jwtVErFrhVutuqXamEWD3G%2BAY5vt6lm5tSmipDbZcqxw2ilCzd%2BFN76zRKnq6d37N4k5%2BIxLQh75rMSFc1amglc1hVjIPFPyWqETApsPKIwQsG1YkIWBoWKmEgM2GFUwI2HxY2YSALYYVTwgsV7tHZhSCTmIhHplUCCQlfBOJ0vsCC4HApqaPjC0EW%2FGlyYWg0GT3pfGFoNx0VssfNxb2DvUEWJYAQwmwWQJsnpzaIRoIFheCJmBZAvZw3Sq0IdTkMG230GnBCRQCl83b%2BVFaaiFw29EcTr%2F%2Bl7xWPdvXPo9a3R6H3LnRC8rhTy9w2dRGbxRJvVv8lWsxOr9YcM7yck5yMnNM3hPeEPm%2B5v2W%2Fwc0echPThAAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{color:{primary:'tythf05',primaryLight:'tythf06',secondary:'tythf07',accent:'tythf08',neutralDark:'tythf09',neutralLight:'tythf0a',black:'tythf0b',white:'tythf0c'},autoMargin:{true:'tythf0d'},bold:{true:'tythf0e'},headingFont:{true:'tythf0f'},variant:{h1:'tythf0g',h2:'tythf0h',h3:'tythf0i',h4:'tythf0j',h5:'tythf0k',h6:'tythf0l',tiny:'tythf0m',small:'tythf0n',medium:'tythf0o',large:'tythf0p',xl:'tythf0q',body:'tythf0r',customBody:'tythf0s',inherit:'tythf0t'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','autoMargin','bold','headingFont','variant']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1f)',lineHeight:'var(--_1i72bi11)'},h2:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1g)',lineHeight:'var(--_1i72bi12)'},h3:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1h)',lineHeight:'var(--_1i72bi13)'},h4:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi14)'},h5:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi15)'},h6:{fontWeight:'var(--_1i72bi1t)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi16)'},tiny:{fontSize:'var(--_1i72bi1q)',lineHeight:'var(--_1i72bi17)'},small:{fontSize:'var(--_1i72bi1p)',lineHeight:'var(--_1i72bi18)'},medium:{fontSize:'var(--_1i72bi1o)',lineHeight:'var(--_1i72bi19)'},large:{fontSize:'var(--_1i72bi1n)',lineHeight:'var(--_1i72bi1a)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1m)',lineHeight:'var(--_1i72bi1c)'},body:{fontWeight:'var(--_1i72bi1r)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi1b)'},customBody:{fontWeight:'var(--_1i72bi1r)'},inherit:{}};
export var withHtml = 'tythf03';