'use client';
import { PAGE_PARAM, SEARCH_PARAM } from '../entryIndexConstants';
import { EntryIndexFilterState } from '../entryIndexTypes';
import { stringifySearchParams } from './stringifySearchParams';

export const searchParamsFromState = (state: EntryIndexFilterState | null): URLSearchParams => {
  const { page, filterSelections = {}, search } = state ?? {};

  const query = new URLSearchParams();

  Object.entries(filterSelections).forEach(([filterId, selection]) => {
    if (!selection) return;
    selection = [...selection].sort((a, b) => a.localeCompare(b));
    selection.forEach((id) => {
      query.append(filterId, id);
    });
  });

  if (page && page > 1) {
    query.append(PAGE_PARAM, page.toString());
  }

  if (search) {
    query.append(SEARCH_PARAM, search);
  }

  return query;
};

/**
 * Generates a query string based on the provided state object.
 *
 * @param state - The state object containing page, filter selections, and search values.
 * @returns The generated query string or null if no query parameters are present.
 */
export const selectQueryString = (state: EntryIndexFilterState): string | null => {
  return stringifySearchParams(searchParamsFromState(state)) || null;
};
