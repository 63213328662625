import React from 'react';
import { createStore, StateCreator, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  EntryIndexActions,
  EntryIndexState,
  EntryIndexStateWithActions,
  FilterConfig,
  FilterSlices,
  FilterSliceState,
  IndexProps,
} from './entryIndexTypes';

export const DEFAULT_PER_PAGE = 12;

const createFilterSlices = (filtersConfig: FilterConfig[]) => {
  return filtersConfig.reduce((acc, config) => {
    const { id } = config;

    const sliceState: FilterSliceState = {
      relation: 'OR',
      multiple: true,
      items: [],
      ...config,
    };

    acc[id] = sliceState;
    return acc;
  }, {} as FilterSlices);
};

const entryIndexStateCreator = (options: IndexProps, initialState?: Partial<EntryIndexState>) => {
  const { filtersConfig, ...config } = options;

  const createState: StateCreator<EntryIndexStateWithActions, [], [['zustand/immer', never]]> =
    immer((set, get) => {
      /**
       * Actions for the EntryIndex state
       */
      const indexActions: EntryIndexActions = {
        setBaseEntryQuery: (baseEntryQuery) => {
          set({ baseEntryQuery });
        },
        setState: (state) => {
          set(state);
        },
        setSearch: (search) => {
          set({
            search: search || null,
            page: 1,
          });
        },
        resetFilters: () => {
          set({
            filterSelections: {},
            page: 1,
            search: null,
          });
        },
        setFilterSelections: (filterSelections) => {
          set({
            filterSelections,
            page: 1,
          });
        },
        filterSelect: (filterId, value) => {
          const currentSelection = get().filterSelections[filterId] ?? [];
          const isMultiple = get().filters[filterId].multiple;

          if (currentSelection.includes(value)) return;

          set((state) => {
            state.filterSelections[filterId] = isMultiple ? [...currentSelection, value] : [value];
            state.page = 1;
          });
        },
        filterRemove: (filterId, value) => {
          const currentSelection = get().filterSelections[filterId] ?? [];

          if (!currentSelection.includes(value)) return;

          set((state) => {
            state.filterSelections[filterId] = currentSelection.filter((v) => v !== value);
            state.page = 1;
          });
        },
        filterClear: (filterId) => {
          set((state) => {
            state.filterSelections[filterId] = [];
            state.page = 1;
          });
        },
        setLoading: (loading) => {
          set({ loading });
        },
        setError: (error) => {
          set({ error });
        },
        setItems: (items) => {
          set({ items });
        },
        setTotalItems: (total) => {
          set({ totalItems: total });
        },
        setPage: (page) => {
          set({ page });
        },
        setInitialLoaded: (loaded = true) => {
          set({ initialLoaded: loaded });
        },
      };

      return {
        search: null,
        filterSelections: {},
        filters: createFilterSlices(filtersConfig),
        perPage: DEFAULT_PER_PAGE,
        page: 1,
        totalItems: 0,
        items: [],
        initialLoaded: false,
        ...config,
        ...initialState,
        error: null,
        loading: false,
        ...indexActions,
      };
    });

  return createState;
};

export const createEntryIndexStore = (
  options: IndexProps,
  initialState?: Partial<EntryIndexState>
) => {
  return createStore(entryIndexStateCreator(options, initialState));
};

export type EntryIndexStore = ReturnType<typeof createEntryIndexStore>;

export const EntryIndexContext = React.createContext<EntryIndexStore | null>(null);

export function useEntryIndex<T>(selector: (state: EntryIndexStateWithActions) => T): T {
  const store = React.useContext(EntryIndexContext);
  if (!store) throw new Error('Missing EntryIndex.Provider in the tree');
  return useStore(store, selector);
}
