import { LOCALE, TIMEZONE } from '@/lib/constants';

import {
  firstNonNullable,
  makeNonNullableArray,
  toNumber,
} from '@liquorice/allsorts-craftcms-nextjs';
import { Category } from '../parsers/categories';
import { DisplayRoleMode } from '../parsers/common';

export const fmtRole = (
  role: string,
  displayRoleMode: DisplayRoleMode,
  displayRole?: string | null
) => {
  if (displayRoleMode === 'none') return role;
  if (displayRoleMode === 'appendToRole') return `${role}, ${displayRole}`;
  if (displayRoleMode === 'overwriteRole') return displayRole;
};

export const fmtCategoryNames = (
  maybeCats?: MaybeArrayOf<Category>,
  join = ', ',
  exclude?: MaybeArrayOf<Category>
) => {
  const excludeIds = makeNonNullableArray(exclude).map((v) => v.id);
  const cats = makeNonNullableArray(maybeCats).filter((v) => !excludeIds.includes(v.id));

  return cats.map((v) => v.title).join(join);
};

export const fmtCategoryNameAndId = (
  maybeCats?: MaybeArrayOf<Category>,
  exclude?: MaybeArrayOf<Category>
): { title: string; id: string } | undefined => {
  const excludeIds = makeNonNullableArray(exclude).map((v) => v.id);
  const cats = makeNonNullableArray(maybeCats).filter((v) => !excludeIds.includes(v.id));

  return firstNonNullable(
    cats.map((v) => {
      return { title: v.title as string, id: v.id };
    })
  );
};

export const fmtDate = (n?: string | number | null) => {
  if (!n) return undefined;
  const date = new Date(n);
  return `${date.getDate()} ${date.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  })} ${date.getFullYear()}`;
};

export const fmtDateRange = (
  start?: string | number | null,
  end?: string | number | null,
  join = '-'
) => {
  if (!start) return fmtDate(end);
  if (!end) return fmtDate(start);
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const endMonth = endDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();

  const endStr = `${endDay} ${endMonth}, ${endYear}`;

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) return endStr;

      return `${startDay}${join}${endStr}`;
    }
    return `${startDay} ${startMonth} ${join}${endStr}`;
  }
  return `${startDay} ${startMonth}, ${startYear}${join}${endStr}`;
};

export const fmtTime = (value?: string | number | null, options?: Intl.DateTimeFormatOptions) => {
  if (!value) return null;

  const time = new Date(value);

  const formattedTime = time
    .toLocaleString(LOCALE, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: TIMEZONE,
      ...options,
    })
    .replace(' ', '');

  return formattedTime;
};

export const fmtTimeRange = (
  start?: string | number | null,
  end?: string | number | null,
  join = '-',
  options?: Intl.DateTimeFormatOptions
) => {
  if (!start) return fmtTime(end, options);
  if (!end) return fmtTime(start, options);

  return `${fmtTime(start, { ...options, timeZoneName: undefined })}${join}${fmtTime(
    end,
    options
  )}`;
};

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

export const roundToTwo = (n: string | number | null) => {
  return Math.round(toNumber(n) * 100) / 100;
};

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};

export const fmtProperNoun = (str: string): string => {
  return str.replace(/^\w/, (c) => c.toUpperCase());
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const fmtTitleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const fmtFullName = (
  honorific?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  if (!firstName || !lastName) return null;

  return `${honorific ?? ''} ${firstName ?? ''} ${lastName ?? ''}`;
};

export const fmtPodcastLink = (str?: string | null): string | null => {
  if (!str) return null;

  // Define the regex patterns for different Spotify types
  const patterns: { [key: string]: RegExp } = {
    episode: /https:\/\/open\.spotify\.com\/episode\/([a-zA-Z0-9]+)\?.*/,
    track: /https:\/\/open\.spotify\.com\/track\/([a-zA-Z0-9]+)\?.*/,
    album: /https:\/\/open\.spotify\.com\/album\/([a-zA-Z0-9]+)\?.*/,
  };

  // Iterate through the patterns to find a match
  for (const [type, pattern] of Object.entries(patterns)) {
    const match = str.match(pattern);
    if (match) {
      const id = match[1];
      return `https://open.spotify.com/embed/${type}/${id}`;
    }
  }

  return null;
};
