/* eslint-disable @typescript-eslint/no-explicit-any */
// import { gql } from '__generated__';
import { FormValues } from '@/components/Formie/formTypes';
import { gql, TypedDocumentNode } from '@apollo/client';

export type FormSubmissionProps = {
  mutationName: string;
  formValues: FormValues;
};

// Create a fake mutation just for typing
export type GenericFormSubmissionMutationVariables = Exact<{ [key: string]: never }>;

export type GenericFormSubmissionMutation = {
  __typename?: 'Mutation';
  submission?: { __typename?: 'test_Submission'; uid?: string | null } | null;
};

export type GenericFormSubmissionDocument = TypedDocumentNode<
  GenericFormSubmissionMutation,
  GenericFormSubmissionMutationVariables
>;

export const parseSubmitFormQuery = ({ mutationName, formValues }: FormSubmissionProps) => {
  // Prepare the arguments for the mutation
  const argsStr = JSON.stringify(formValues, (key, value) => value ?? null)
    // Remove the quotes around keys
    .replace(/"([^"]+)":/g, '$1:')
    // trim the first and last curly braces
    .slice(1, -1);

  try {
    const str = gql(`
      mutation submitForm {
        submission: ${mutationName}(${argsStr}) {
          uid
        }
      }
    `) as GenericFormSubmissionDocument;
    return str;
  } catch (error) {
    console.error(error);
    return null;
  }
};
